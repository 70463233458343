.sentimentMentionsWrap {

}
.sentMentBx > .gBCtnt > .innerBox {

}
.sentMentBx > .gBCtnt > .innerBox > .headerRow {
  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.sentMentBx > .gBCtnt > .innerBox > .optionsRow {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  gap: 5px;
}
.sentMentBx > .gBCtnt > .innerBox> .optionsRow > .search {
  flex: 1;
  width: 100%;
}
.sentMentBx > .gBCtnt > .innerBox > .optionsRow > .search > input {
  width: 100%;
  color: var(--primary-text-color);
  border: var(--gt-form-input-border);
  border-radius: var(--gt-form-input-border-radius);
  padding: 9px 12px 8px 12px;
  margin-bottom: 1px;
  color: var(--primary-text-color);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}
.sentMentBx > .gBCtnt > .innerBox > .optionsRow > .filter {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.sentMentBx > .gBCtnt > .innerBox > .contentBox {
}
.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
}
.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow > .icon {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #FFFFFF;
  margin-right: 10px;
}
.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow.positive > .icon {
  background-color: lightgreen;
}
.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow.negative > .icon {
  background-color: red;
}
.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow > .text {
  flex: 1;
  margin-top: -2px;
}

.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow > .text > .hName {
  font-size: 12px;
}

.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow > .text > .showReview {
  margin-left: 10px;
  color: grey;
  cursor: default;
}
.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow > .text > .reviewText {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-top: 10px;
  background-color: #EEE;
  border-radius: 5px;
  padding: 20px;
}
.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow > .text > .reviewText > p {
  margin-bottom: 5px;
}
.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow > .text > .reviewText > p.hName {
  margin-bottom: 10px;
  font-weight: var(--font-weight-bold);
}
.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow > .text > .reviewText > p > img {
  height: 30px;
}

.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow > .text > .reviewText > .rating {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}
.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow > .text > .reviewText > .rating > .stars {
  margin-right: 10px;
}
.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow > .text > .reviewText > .rating > .ratingValue {
  font-size: var(--font-size-primary);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--primary-text-color);
  margin-right: 10px;
}
.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow > .text > .reviewText > .mgrRsp {
  margin-left: 10px;
  margin-top: 20px;
}
.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow > .text > .reviewText > .mgrRsp  > p {
  margin-bottom: 5px;
}
.sentMentBx > .gBCtnt > .innerBox > .contentBox > .singleRow > .text > .reviewText > .mgrRsp > p.title {
  font-weight: var(--font-weight-bold);
  border-left: 5px solid grey;
  padding-left: 10px;
}
