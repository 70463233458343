:root {
  --gte-primary-color: #0d43aa;
  --gt-input-border: solid 1px rgba(37, 39, 52, 0.1);
  --generic-border-radius: 5px;
  --generic-card-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.08);
  --gt-form-input-border-radius: 3px;
  --generic-border-color: #2527341a;
  --generic-border-radius: 5px;

  --gte-font-family: 'Open Sans';
  --font-size-primary: 14px;
  --primary-text-color: #090e1a;
  --primary-text-color-light: #838e9f;

  --font-size-title: 16px;
  --font-size-subtitle: 14px;
  --font-weight-bold: 500;

}

body {
  margin: 0;
  f1ont-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* font-family: 'Montserrat', sans-serif!important;
  font-family: 'Open Sans', sans-serif!important; */
  font-family: var(--gte-font-family)!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--font-size-primary);
  color: var(--primary-text-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
  Use a more-intuitive box-sizing model.
*/
*, *::before, *::after {
  box-sizing: border-box;
}
/*
  Remove default margin
*/
* {
  margin: 0;
  border: 0 none;
}
/*
  Allow percentage-based heights in the application
*/
html, body {
  height: auto!important;
  margin: 0px;
}
/*
  Typographic tweaks!
  Add accessible line-height
  Improve text rendering
*/
body {
  line-height: 1.5!important;
  -webkit-font-smoothing: antialiased;
}
/*
  Improve media defaults
*/
/*img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}*/
/*
  Remove built-in form typography styles
*/
input, button, textarea, select {
  font: inherit;
}
/*
  Avoid text overflows
*/
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
/*
  Create a root stacking context
*/
#root, #__next {
  isolation: isolate;
}


input:focus-visible,
textarea:focus-visible {
  outline: none;
}



.gPg {
  padding: 20px;
}
.gPWrp {
  width: calc(100% - 0px);
  padding: 10px;
  margin-bottom: 0px;
  background-color: #FFFFFF;
}
@media (max-width: 1366px) {
  .gPWrp {
    padding: 20px;
    margin: 0 10px;
    margin: 0;
  }
}
.gPWrp > .gPTle {
  font-size: var(--font-size-title);
  padding-bottom: 30px;
  color: var(--primary-text-color);
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.gPWrp > .gPTle.subTitle {
  color: var(--primary-text-color-light);
  font-size: var(--font-size-subtitle);
}
.gPWrp > .gPTle > span.close {
  font-size: 14px;
  font-weight: var(--font-weight-bold);
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--gte-primary-color);
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: default;
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
}
.gPWrp > .gPTle > span.close:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPWrp > .gPTle > span.close > img {
  margin-right: 5px;
  height: 14px;
}
.gPWrp > .gPTle.add {
  justify-content: flex-start;
  gap: 10px;
}
.gPWrp > .gPTle.add > span.add {
  padding: 4px 6px;
  border-radius: var(--generic-border-radius);
}
.gPWrp > .gPTle.add > span.add:hover {
  background-color: var(--sidebar-main-menu-active-bg);
}
.gPWrp > .gPSubTle {
  font-size: 14px;
  padding-bottom: 30px;
  color: var(--primary-text-color);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  display: flex;
  flex-direction: row;
}
.gPWrp > .gBCtnt {
  display: flex;
  flex-direction: column;
}
.gPWrp > .gBCtnt > .gBCtRw {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  background-color: #FFFFFF;
  line-height: 1.43;
  font-size: var(--font-size-primary);
  color: var(--primary-text-color);
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
.gPWrp > .gBCtnt > .gBCtRw > .gRwBxLt {
  flex: 1;
}
.gPWrp > .gBCtnt > .gBCtRw.btnRw {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: inherit;
}
.gPWrp > .gBCtnt > .gBCtRw.curSts {
  font-weight: var(--font-weight-bold);
  margin-top: 20px;
  margin-bottom: 0;
  background-color: inherit;
}
.gPWrp > .gBCtnt > .gBCtRw.curSts > .success {
  color: var(--form-success-color);
}
.gPWrp > .gBCtnt > .gBCtRw.curSts > .error {
  color: var(--form-error-color);
}
.gPWrp > .gBCtnt > .gBCtRw > .gRwBxLt {
}
.gPWrp > .gBCtnt > .gBCtRw > .gRwBxRt {
}
.gPWrp > .gBCtnt > .gBCtRw > .lt,
.gPWrp > .gBCtnt > .gBCtRw > .rt {
  display: flex;
  flex-direction: column;
}
.gPWrp > .gBCtnt > .gBCtRw > .lt > .title,
.gPWrp > .gBCtnt > .gBCtRw > .rt > .title {
  font-weight: var(--font-weight-bold);
  margin-bottom: 5px;
}
.gPWrp > .gBCtnt > .gBCtRw > .lt > .desc {
  color: var(--primary-text-color-light);
}
.gPWrp > .gBCtnt > .gBCtRw > .lt > .title > i {
  margin-left: 5px;
}

.gPWrp > .gBCtnt > .gBCtRw.rdOlyRw {
  padding: 0!important;
  background-color: inherit;
}


.gIpt input,
.gIpt textarea  {
  width: 100%;
  color: var(--primary-text-color);
  border: var(--gt-input-border);
  border-radius: var(--gt-form-input-border-radius);
  padding: 9px 12px 8px 12px;
  margin-bottom: 1px;
  color: var(--primary-text-color);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.37;
  letter-spacing: normal;
  resize: vertical;
}
.gIpt.error,
.gIpt.error input, .error > .gIpt.error textarea,
.gIpt.error textarea,
textarea.gInput.error  {
  border-color: var(--generic-error-color);
}
.gIpt > input:read-only,
.gIpt > textarea:read-only {
  background-color: var(--gt-form-input-readonly);
  cursor: default;
}

/********************** Modal **************************/

#modal-root {
  position: relative;
  z-index: 999;
}
.cModal {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cModal > .content {
  max-width: 800px;
  width: 60%;
  width: 700px;
  max-height: 800px;
  1height: 90%;
  background-color: #FFFFFF;
  border-radius: 5px;
}
.cModal > .content > .close {
  float: right;
  margin-right: -3px;
  margin-top: -3px;
  cursor: pointer;
}
.cModal > .content > .close > .fas {
  font-size: 20px;
}
/* ****************************** */

/********************** Side Modal **************************/

#modal-root {
  position: relative;
  z-index: 999;
}
.sdMdl {
  background-color: rgba(0,0,0,0.5);
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0!important;

}
.sdMdl > .ctnt {
  m1ax-width: 900px;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  transition: all 0.5s;
  animation: slideInRight 0.5s;
  overflow-y: scroll;
  padding: 0px;
}
.sdMdl > .ctnt::-webkit-scrollbar-track
{
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);*/
	border-radius: 10px;
	background-color: #ffffff;
}
.sdMdl > .ctnt::-webkit-scrollbar
{
	width: 5px;
	background-color: #ffffff;
}
.sdMdl > .ctnt::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	/*-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);*/
	background-color: #BDBEC1;
}
.sdMdl > .ctnt > .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.sideModal > .ctnt > .title > .close {
  float: right;
  margin-right: -3px;
  margin-top: -3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sdMdl > .ctnt > .title > .close > img {
  margin-right: 5px;
}

/* ************** side modal *************** */


/*********************************************/

.gPnlSplLdng {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  background-color: #ffffff85;
  left: 0;
  top: 0;
}
.gPnlSplLdng > .innerWrap {
  width: 100px;
  height: 100px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 0px solid var(--generic-border-color);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.gPnlSplLdng > .innerWrap > .fa {
  position: fixed;
}
.gPnlSplLdng > .innerWrap > img {
  height: 70px;
  border-radius: 10px;
  z-index: 9;
}
