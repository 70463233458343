.wrp {
  height: 100%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.mb {
  width: 100%;
  height: calc(100% - 0px);
  background-color: #FFFFFF;
  d1isplay: flex;
  f1lex-direction: row;
  margin-top: 0px;
  overflow-y: scroll;
}
