.mentionChartWrap {
  padding: 20px;
  margin: 10px 0 30px 0;
  border-radius: var(--generic-border-radius);
  b1ox-shadow: var(--generic-card-shadow);
  border-bottom: 1px solid var(--generic-border-color);
}
.mentionChartWrap > .chartRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.mentionChartWrap > .chartRow.header {
  margin-bottom: 20px;
}
.mentionChartWrap > .chartRow > .colLeft {
  width: 200px
}
.mentionChartWrap > .chartRow > .colMiddle {
  width: 180px;
}
.mentionChartWrap > .chartRow > .colRight {
  width: 200px
}

.mentionChartWrap > .chartRow.header .colLeft > .barBox {
  justify-content: flex-end;
}
.mentionChartWrap > .chartRow.header .colMiddle > .barBox {
  justify-content: center;
}
.mentionChartWrap > .chartRow.header .colRight > .barBox {
  justify-content: flex-start;
}
.mentionChartWrap > .chartRow.header .colLeft > .barBox > span,
.mentionChartWrap > .chartRow.header .colMiddle > .labelBox > span,
.mentionChartWrap > .chartRow.header .colRight > .barBox > span {
  cursor: pointer;
}
.mentionChartWrap > .chartRow.header .colLeft > .barBox > span > i {
  margin-left: 5px;
}
.mentionChartWrap > .chartRow.header .colRight > .barBox > span > i {
  margin-right: 5px;
}


.mentionChartWrap > .chartRow > .colLeft > .barBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.mentionChartWrap > .chartRow > .colLeft > .barBox > .barShape {
  background-color: #fd868b;
  height: 40px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.mentionChartWrap > .chartRow > .colLeft > .barBox > .barLabel {
  margin-right: 10px;
  margin-top: 10px;
}

.mentionChartWrap > .chartRowAxis > .colLeft > .barBox {
  justify-content: center;
}


.mentionChartWrap > .chartRow > .colMiddle > .labelBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mentionChartWrap > .chartRow > .colMiddle > .labelBox > .name {
}
.mentionChartWrap > .chartRow > .colMiddle > .labelBox > .mention {
  color: grey;
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}


.mentionChartWrap > .chartRow > .colRight > .barBox {
  display: flex;
  flex-direction: row;
}
.mentionChartWrap > .chartRow > .colRight > .barBox > .barShape {
  background-color: #52ced5;
  height: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.mentionChartWrap > .chartRow > .colRight > .barBox > .barLabel {
  margin-left: 10px;
  margin-top: 10px;
}

.mentionChartWrap > .chartRowAxis > .colRight > .barBox {
  justify-content: center;
}
