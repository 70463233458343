.sentimentTrendChartWrap {
  padding: 20px;
  margin: 10px 0 30px 0;
  border-radius: var(--generic-border-radius);
  b1ox-shadow: var(--generic-card-shadow);
  border-bottom: 1px solid var(--generic-border-color);
}

.sentimentTrendChartWrap > .aspectsRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
.sentimentTrendChartWrap > .aspectsRow > .entry {
  padding: 6px 12px;
  margin: 5px;
  border-radius: var(--generic-border-radius);
  box-shadow: var(--generic-card-shadow);
  cursor: pointer;
}
.sentimentTrendChartWrap > .aspectsRow > .entry.active {
  background-color: #EEE;
}


.sentimentTrendChartWrap > .bottomRow > .timePeriodBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.sentimentTrendChartWrap > .bottomRow > .timePeriodBox > .entry {
  padding: 6px;
  cursor: pointer;
  background-color: rgba(245, 246, 250, 0.4);
  border: solid 1px rgba(245, 246, 250, 0.4);
  border-radius: 5px;
}
.sentimentTrendChartWrap > .bottomRow > .timePeriodBox > .entry.active {
  background-color: #FFFFFF;
  border: solid 1px #0d43aa;
}


.sentimentTrendChartWrap #lineChart > circle {
  fill: #0d43aa;
}
.sentimentTrendChartWrap #lineChart > .dotText {
  font-weight: bold;
  fill: #0d43aa;
}


.sentimentTrendChartWrap .line {
  fill: none;
  stroke: #0d43aa;
  stroke-width: 2px;
}
