.sentimentScoreChartWrap {
  padding: 20px;
  margin: 10px 0 30px 0;
  border-radius: var(--generic-border-radius);
  b1ox-shadow: var(--generic-card-shadow);
  border-bottom: 1px solid var(--generic-border-color);
}

.sentimentScoreChartWrap > .chartRow {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.sentimentScoreChartWrap > .chartRow.header {
  margin-bottom: 20px;
}
.sentimentScoreChartWrap > .chartRow > .colLeft {
  width: 180px;
}
.sentimentScoreChartWrap > .chartRow > .colRight {
  width: calc(100% - 180px);
}

.sentimentScoreChartWrap > .chartRow.header .colLeft > .barBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
}
.sentimentScoreChartWrap > .chartRow.header .colRight > .barBox {
  justify-content: flex-start;
  margin-left: 10px;
}
.sentimentScoreChartWrap > .chartRow.header .colLeft > .barBox > span,
.sentimentScoreChartWrap > .chartRow.header .colRight > .barBox > span {
  cursor: pointer;
}
.sentimentScoreChartWrap > .chartRow.header .colLeft > .barBox > span > i {
  margin-left: 5px;
}
.sentimentScoreChartWrap > .chartRow.header .colRight > .barBox > span > i {
  margin-right: 5px;
}

.sentimentScoreChartWrap > .chartRow > .colLeft > .labelBox {
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.sentimentScoreChartWrap > .chartRow > .colLeft > .labelBox > .name {
}
.sentimentScoreChartWrap > .chartRow > .colLeft > .labelBox > .mention {
  color: grey;
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}

.sentimentScoreChartWrap > .chartRow > .colRight > .barBox {
  display: flex;
  flex-direction: row;
}
.sentimentScoreChartWrap > .chartRow > .colRight > .barBox > .barShape {
  background-color: grey;
  height: 40px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.sentimentScoreChartWrap > .chartRow > .colRight > .barBox > .barShape.right {
  background-color: #52ced5;
}
.sentimentScoreChartWrap > .chartRow > .colRight > .barBox > .barShape.left {
  background-color: #fd868b;
}
.sentimentScoreChartWrap > .chartRow > .colRight > .barBox > .barLabel {
  margin-left: 10px;
  margin-top: 10px;
}

.sentimentScoreChartWrap > .chartRowAxis > .colRight > .barBox {
  justify-content: center;
}

.sentimentScoreChartWrap > .footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 20px;
  margin-top: 30px;
}
